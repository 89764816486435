import { NEWS_LIST } from '@/apollo/queries/news'
import {
  NewsListQuery,
  NewsListQueryVariables,
  OrderEnum,
  PostObjectsConnectionOrderbyEnum,
} from '@/apollo/types'
import { Button } from '@/components/button'
import { Container } from '@/components/container'
import { InViewFadeIn, InViewFadeInUp } from '@/components/fade-in'
import { Heading } from '@/components/heading'
import {
  ThreeStagesBlockBody,
  ThreeStagesBlockHeading,
} from '@/components/three-stages-block'
import { formatDate } from '@/helpers/format-date'
import { styles } from '@/styles/styles'
import { useQuery } from '@apollo/client'
import { css, useTheme } from '@emotion/react'
import { Link } from 'gatsby'
import * as React from 'react'
import { SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useMedia from 'use-media'

// @ts-ignore
import mainCopy from '@/images/main_copy-01.svg'
// @ts-ignore
import decoration from '@/images/decoration-01.svg'
// @ts-ignore
import decorationSp from '@/images/decoration-01-sp.svg'
// @ts-ignore
import background from '@/images/background-01.svg'
// @ts-ignore
import background2 from '@/images/background-02.svg'
// @ts-ignore
import mitsubishiLogo from '@/images/mitsubishi_mirai-logo.svg'
// @ts-ignore
import iconStage01 from '@/images/icon_stage-01.svg'
// @ts-ignore
import iconStage02 from '@/images/icon_stage-02.svg'
// @ts-ignore
import iconStage03 from '@/images/icon_stage-03.svg'
// @ts-ignore
import mainVideo01 from '@/videos/main_video-01.webm'
// @ts-ignore
import mainVideo01mp4 from '@/videos/main_video-01.mp4'

export default function IndexPage() {
  const { data } = useQuery<NewsListQuery, NewsListQueryVariables>(NEWS_LIST, {
    variables: {
      where: {
        offsetPagination: { offset: 0, size: 4 },
        orderby: [
          {
            field: PostObjectsConnectionOrderbyEnum.Date,
            order: OrderEnum.Desc,
          },
        ],
      },
    },
  })

  return (
    <>
      <HeroSection />
      <NewsSection data={data} />
      <ProjectIntroductionSection />
    </>
  )
}

function HeroSection() {
  const theme = useTheme()

  return (
    <section>
      <InViewFadeIn
        css={[
          styles.relative,
          css`
            --decoration-height: 1.5rem;
            height: 100vh;

            @media ${theme.breakpoints.md} {
              --decoration-height: 2.5rem;
            }
          `,
        ]}
      >
        <video
          autoPlay
          loop
          muted
          css={[
            styles.fullWidth,
            css`
              object-fit: cover;
              height: calc(100vh - var(--decoration-height));
            `,
          ]}
        >
          <source src={mainVideo01} type="video/webm" />
          <source src={mainVideo01mp4} type="video/mp4" />
        </video>

        <div
          css={[
            styles.absolute,
            styles.fullWidth,
            css`
              top: 0;
              left: 0;
              height: calc(100vh - var(--decoration-height));
              background-color: #79aabf;
              mix-blend-mode: multiply;
            `,
          ]}
        />

        <h1
          css={[
            styles.absolute,
            css`
              left: 3.6rem;
              bottom: 10rem;

              @media ${theme.breakpoints.md} {
                left: 7.6rem;
                bottom: 18rem;
              }
            `,
          ]}
        >
          <img
            src={mainCopy}
            alt="国際環境で地球規模の問題に挑戦する"
            css={css`
              width: 80vw;
              max-width: 50rem;

              @media ${theme.breakpoints.md} {
                max-width: 73.6rem;
              }
            `}
          />
        </h1>

        <div
          css={[
            styles.absolute,
            css`
              bottom: 0;
              left: 0;
              width: 100%;
              height: var(--decoration-height);
              background: url(${decorationSp}) center;

              @media ${theme.breakpoints.md} {
                background-image: url(${decoration});
                background-size: 192rem;
              }
            `,
          ]}
        />
      </InViewFadeIn>
    </section>
  )
}

function NewsSection({ data }: { data?: NewsListQuery }) {
  const theme = useTheme()

  return (
    <section
      css={theme => css`
        padding: 11rem 4rem;
        background: ${theme.colors.gray.lighter} url(${background}) center /
          192rem;

        @media ${theme.breakpoints.md} {
          padding-bottom: 20rem;
        }
      `}
    >
      <InViewFadeInUp>
        <Container>
          <Heading
            css={css`
              padding-bottom: 2.8rem;
              border-bottom: solid;
              border-image: url(${decoration}) 0 0 7 / 0.4rem repeat;
            `}
          >
            新着情報
          </Heading>

          <div
            css={css`
              display: grid;
              grid-template-columns: 10rem 1px 1fr;
              align-items: center;
              gap: 3rem 3rem;
              margin-top: 4rem;

              @media ${theme.breakpoints.md} {
                grid-template-columns: 13.4rem 2px 1fr;
                gap: 3rem 4rem;
              }
            `}
          >
            {data?.newsList?.nodes?.map(node => {
              const { id, date, title } = node ?? {}
              const uri = `/news?id=${id ?? ''}`
              return (
                <div
                  key={id}
                  css={css`
                    display: contents;
                  `}
                >
                  <Link
                    to={uri}
                    css={css`
                      display: block;
                      color: ${theme.colors.magenta.main};
                      font-family: ${theme.fonts.roboto};
                      font-size: ${theme.fontSizes.body3};
                      font-weight: ${theme.fontWeights.regular};
                      letter-spacing: ${theme.letterSpacings.tight};

                      @media ${theme.breakpoints.md} {
                        font-size: ${theme.fontSizes.body1};
                      }
                    `}
                  >
                    {formatDate(date ?? '')}
                  </Link>

                  <div
                    css={css`
                      background-color: currentColor;
                      height: 3.5rem;
                    `}
                  />

                  <Link
                    to={uri}
                    css={css`
                      display: block;
                      letter-spacing: ${theme.letterSpacings.wide};
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      transition: color 0.25s ease-out;

                      @media ${theme.breakpoints.md} {
                        &:hover {
                          color: ${theme.colors.magenta.main};
                          text-decoration: underline;
                        }
                      }
                    `}
                  >
                    {title}
                  </Link>
                </div>
              )
            })}
          </div>

          <div
            css={[
              styles.textCenter,
              css`
                margin-top: 10rem;
              `,
            ]}
          >
            <Link to="/news-list">
              <Button>VIEW MORE</Button>
            </Link>
          </div>
        </Container>
      </InViewFadeInUp>
    </section>
  )
}

function ProjectIntroductionSection() {
  const theme = useTheme()

  return (
    <section
      css={[
        styles.relative,
        css`
          padding: 17.4rem 4rem 19.4rem;
        `,
      ]}
    >
      <InViewFadeInUp
        css={[
          styles.absolute,
          css`
            top: -2rem;
            left: 50%;
            transform: translateX(-50%);

            @media ${theme.breakpoints.md} {
              top: -3rem;
            }
          `,
        ]}
      >
        <Heading>プロジェクト紹介</Heading>

        <div
          css={css`
            margin: 5rem auto 0;
            width: 8.4rem;
            height: 0.7rem;
            background-color: ${theme.colors.orange.main};
          `}
        />
      </InViewFadeInUp>

      <Container>
        <InViewFadeInUp>
          <p
            css={css`
              line-height: ${theme.lineHeight.loose};
            `}
          >
            気候変動、新型ウィルスの出現、大国による開戦など、全人類の生活に影響するような喫緊の問題が次々と起きており、そのような時代に対応できるニュータイプ人材の育成が望まれる。名大みらい育成プロジェクトでは「Global30
            国際プログラム」の外国人教員が中心となり、高校生を対象に英語を基本言語とした講義、ワークショップを実施します。コミュニケーション面では留学生ティーチング・アシスタントのサポートを受けながら、状況分析力、計画立案力、ソリューション提案力、多様性理解力を磨きます。さらに本学の研究室に於いてプロジェクト型学習を実施し、その成果を海外で研究発表することで主体性や行動力を養います。文系・理系の垣根無く、世界規模の問題解決に向けて国際的に活躍できるリーダーの育成を目指します。
          </p>

          <a
            href="https://www.mmfe.or.jp/"
            target="_blank"
            rel="noopener noreferrer"
            css={[
              styles.flexCenter,
              css`
                margin-top: 5.5rem;
                height: 7.5rem;
                background-color: ${theme.colors.gray.lighter};

                @media ${theme.breakpoints.md} {
                  transition: filter 0.3s ease-out;
                  &:hover {
                    filter: brightness(0.9);
                  }
                }
              `,
            ]}
          >
            <img
              src={mitsubishiLogo}
              alt="一般財団法人 三菱みらい育成財団"
              css={css`
                width: 28rem;
              `}
            />
          </a>

          <div
            css={[
              styles.textCenter,
              css`
                margin-top: 1rem;
              `,
            ]}
          >
            <small
              css={[
                css`
                  font-size: ${theme.fontSizes.body3};
                  letter-spacing: ${theme.letterSpacings.ultratight};
                `,
              ]}
            >
              このプロジェクトは
              <span css={styles.inlineBlock}>
                一般財団法人 三菱みらい育成財団の
              </span>
              <span css={styles.inlineBlock}>助成により実施します。</span>
            </small>
          </div>
        </InViewFadeInUp>

        <ThreeStageSection />
      </Container>
    </section>
  )
}

function ThreeStageSection() {
  const theme = useTheme()
  const geMd = useMedia(theme.breakpoints.md)

  const innerStyles = {
    stage: {
      container: css`
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
      `,
      heading: css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33rem;
        height: 6.6rem;
        border-radius: 0 9999px 9999px 0;
        color: ${theme.colors.white};
        background: url(${background2}) left top / 100%;

        strong {
          font-family: ${theme.fonts.roboto};
          font-size: ${theme.fontSizes.heading0};
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: -7.8rem;
          z-index: 1;
          transform: translateY(-50%);
          width: 9rem;
          height: 9rem;
          background-color: ${theme.colors.white};
          border-radius: 0 9999px 9999px 0;
        }
      `,
      text1: css`
        padding: 2rem;
        font-weight: ${theme.fontWeights.medium};
        text-align: center;
      `,
      label: css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17.3rem;
        height: 3rem;
        font-weight: ${theme.fontWeights.medium};
        text-align: center;
        border-radius: 9999px;
        color: white;
      `,
      icon: css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 14.4rem;
        margin-top: 5rem;
      `,
      text2: css`
        margin-top: 2rem;
        text-align: center;

        strong {
          font-size: ${theme.fontSizes.body0};
        }
      `,
    },
  }

  const swiperSettings: SwiperOptions = {
    centeredSlides: true,
    slidesPerView: 'auto',
  }

  const threeStages = [
    <div
      key={1}
      css={[
        innerStyles.stage.container,
        css`
          font-weight: ${theme.fontWeights.bold};
          z-index: 2;
        `,
      ]}
    >
      <h3
        css={[
          innerStyles.stage.heading,
          css`
            background-color: ${theme.colors.green.main};

            &::before {
              content: unset;
            }
          `,
        ]}
      >
        <span>
          第<strong>1</strong>
          ステージ
        </span>
      </h3>

      <p
        css={[
          innerStyles.stage.text1,
          css`
            color: ${theme.colors.green.main};
          `,
        ]}
      >
        講義を聞いて
        <br />
        レポートを提出
      </p>

      <div
        css={[
          innerStyles.stage.label,
          css`
            background-color: ${theme.colors.green.main};
          `,
        ]}
      >
        150人限定
      </div>

      <div css={innerStyles.stage.icon}>
        <img
          src={iconStage01}
          alt=""
          css={css`
            width: 13.5rem;
          `}
        />
      </div>

      <p css={innerStyles.stage.text2}>
        地球規模の
        <br />
        <strong>問題の特徴</strong>を<strong>理解</strong>する
      </p>
    </div>,
    <div
      key={2}
      css={[
        innerStyles.stage.container,
        css`
          z-index: 1;
          font-weight: ${theme.fontWeights.bold};
        `,
      ]}
    >
      <h3
        css={[
          innerStyles.stage.heading,
          css`
            background-color: ${theme.colors.yellow.main};
          `,
        ]}
      >
        <span>
          第<strong>2</strong>
          ステージ
        </span>
      </h3>

      <p
        css={[
          innerStyles.stage.text1,
          css`
            color: ${theme.colors.yellow.main};
          `,
        ]}
      >
        毎回異なるテーマの
        <br />
        ワークショップ
      </p>

      <div
        css={[
          innerStyles.stage.label,
          css`
            background-color: ${theme.colors.yellow.main};
          `,
        ]}
      >
        32人限定
      </div>

      <div css={innerStyles.stage.icon}>
        <img
          src={iconStage02}
          alt=""
          css={css`
            width: 13.8rem;
          `}
        />
      </div>

      <p css={innerStyles.stage.text2}>
        問題解決の
        <br />
        <strong>手法を学ぶ</strong>
      </p>
    </div>,
    <div
      key={3}
      css={[
        innerStyles.stage.container,
        css`
          z-index: 0;
          font-weight: ${theme.fontWeights.bold};
        `,
      ]}
    >
      <h3
        css={[
          innerStyles.stage.heading,
          css`
            background-color: ${theme.colors.magenta.main};
          `,
        ]}
      >
        <span>
          第<strong>3</strong>
          ステージ
        </span>
      </h3>

      <p
        css={[
          innerStyles.stage.text1,
          css`
            color: ${theme.colors.magenta.main};
          `,
        ]}
      >
        名古屋大学の研究室で
        <br />
        プロジェクト型学習
      </p>

      <div
        css={[
          innerStyles.stage.label,
          css`
            background-color: ${theme.colors.magenta.main};
          `,
        ]}
      >
        16人限定
      </div>

      <div css={innerStyles.stage.icon}>
        <img
          src={iconStage03}
          alt=""
          css={css`
            width: 13.5rem;
          `}
        />
      </div>

      <p
        css={[
          innerStyles.stage.text2,
          css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 3em;
          `,
        ]}
      >
        <strong>主体性</strong>の<strong>発揮</strong>
      </p>
    </div>,
  ]

  return (
    <section
      css={css`
        margin-top: 13rem;
      `}
    >
      <Container>
        <InViewFadeInUp>
          <ThreeStagesBlockHeading>
            さまざまな力と能力が育つ
            <strong>3</strong>
            つのステージ
          </ThreeStagesBlockHeading>

          <ThreeStagesBlockBody>
            <span>
              <span>第１ステージでは地球規模の問題の</span>
              <span>特徴について理解し、</span>
            </span>

            <span>
              <span>第２ステージでは問題解決の手法を学び、</span>
            </span>

            <span>
              <span>第3ステージでは名古屋大学の研究室で</span>
              <span>プロジェクト型学習を経験します。</span>
            </span>
          </ThreeStagesBlockBody>
        </InViewFadeInUp>

        <InViewFadeInUp>
          <div
            css={css`
              margin: 8rem calc(50% - 50vw);
              width: 100vw;

              @media ${theme.breakpoints.md} {
                margin: 8rem 0 0;
                width: 100%;
              }
            `}
          >
            {geMd ? (
              <div
                css={css`
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                  justify-content: center;
                `}
              >
                {threeStages}
              </div>
            ) : (
              <Swiper
                css={css`
                  .swiper-slide {
                    position: relative;
                    width: fit-content;
                    z-index: 1;

                    &:first-of-type {
                      z-index: 2;
                    }

                    &:last-of-type {
                      z-index: 0;
                    }
                  }
                `}
                {...swiperSettings}
              >
                {threeStages.map((item, index) => (
                  <SwiperSlide key={index}>{item}</SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>

          <div
            css={[
              styles.textCenter,
              css`
                margin-top: 12rem;
              `,
            ]}
          >
            <Link to="/introduction/ja">
              <Button>VIEW MORE</Button>
            </Link>
          </div>
        </InViewFadeInUp>
      </Container>
    </section>
  )
}
